import { Flex, Image, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"

import { getPlaceholderData } from "areas/collection/products/product-image"

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const StyledImage = styled(Image)`
  &.lazyloading {
    animation: ${shimmer} 1.5s forwards infinite;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    overflow: hidden;
  }
`

const ProductName = styled(Typography).attrs({
  fontSize: "16px",
  mb: { _: "10px", tablet: 1 },
  mt: { widescreen: 3 },
  px: "6px",
})``

const CTA = styled(Typography).attrs({
  fontSize: { _: "14px", tablet: "16px" },
  fontWeight: 500,
  lineHeight: { _: "124%", tablet: "18px" },
})`
  text-decoration: underline;
`

const ProductLink = styled(Link)`
  &:focus,
  &:hover {
    ${CTA} {
      text-decoration: none;
    }
  }
`

const Container = styled(Flex).attrs((props) => ({
  border: "1px solid #d9d9d9",
  flexDirection: "column",
  height: "100%",
  pb: { _: 2, tablet: 4 },
  pt: 2,
  textAlign: "center",
  ...props,
}))``

const ColumnProduct = ({ element, index, lazyload, product }) => {
  const productLook = product.looks
    ? product.looks[product.default_look] ||
      product.looks[Object.keys(product.looks)[0]]
    : null

  if (!productLook) return null

  const placeholder = getPlaceholderData(product.product_type)

  return (
    <ProductLink
      aria-label={product.name}
      data-lc={element?.anchor_name || element?.id}
      to={productLook.product_url}
    >
      <Container {...element.style?.item_container_style}>
        <StyledImage
          alt={product.name}
          fallback={require(`images/collections/placeholder_masks/${
            placeholder[0]
          }_${(index % placeholder[1]) + 1}.svg`)}
          lazyload={lazyload}
          ratio="1 / 1"
          srcSet={productLook.images[0]}
        />
        <Flex flex="1" flexDirection="column" justifyContent="space-between">
          <ProductName variant="body">{product.name}</ProductName>
          <CTA aria-hidden>Shop Now</CTA>
        </Flex>
      </Container>
    </ProductLink>
  )
}

ColumnProduct.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
  lazyload: PropTypes.bool,
  product: PropTypes.object,
}

export default ColumnProduct
