import { Box, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { to_currency } from "helpers/number"

const ItemPriceWrap = styled(Box).attrs((props) => ({
  alignItems: "center",
  mb: "12px",
  ...props.theme.cart.itemPriceWrap,
}))``
const DisplayPrice = styled(Typography).attrs((props) => ({
  as: "h5",
  color: props.free ? "greenDarker" : !props.regular ? "sale" : "black",
  fontSize: props.free ? "24px" : "30px",
  fontWeight: "300",
}))``
const RetailPrice = styled(Typography).attrs({
  color: "grey.62",
  fontSize: "12px",
  fontWeight: "500",
  letterSpacing: "1px",
})``

const LineAddonPrice = ({ displayPrice, retailPrice, showSavings }) => {
  return (
    <ItemPriceWrap>
      {displayPrice == "0.0" ? (
        <DisplayPrice free>FREE</DisplayPrice>
      ) : (
        <>
          {showSavings && (
            <Typography
              bold
              color="sale"
              fontSize={{ _: 10, tablet: 16 }}
              letterSpacing="2px"
              lineHeight={{ _: "1.5", tablet: "1" }}
            >
              SALE
            </Typography>
          )}
          <DisplayPrice regular={displayPrice == retailPrice}>
            {to_currency(displayPrice)}
          </DisplayPrice>
        </>
      )}
      {showSavings && (
        <RetailPrice>
          RETAIL <strike>{to_currency(retailPrice)}</strike>
        </RetailPrice>
      )}
    </ItemPriceWrap>
  )
}

LineAddonPrice.propTypes = {
  displayPrice: PropTypes.number,
  retailPrice: PropTypes.number,
  showSavings: PropTypes.bool,
}

export default LineAddonPrice
