// import { faGem } from "@jewlr/pro-light-svg-icons/faGem"
// import { faGifts } from "@jewlr/pro-light-svg-icons/faGifts"
import { faTruckFast } from "@jewlr/pro-light-svg-icons/faTruckFast"
import {
  Box,
  Flex,
  // Image,
  FontAwesomeIcon,
  MediaBox,
  // MediaFlex,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
// import { Link } from "react-router-dom"
import styled from "styled-components"

// import { showHolidayCutoff } from "helpers/holiday"

// import BridalDiscount from "./bridal-discount"
// import GiftCardPromo from "./gift-card-promo"
// import InstantGift from "./instant-gift"
// import MultiItemDiscount from "./multi-item-discount"
// import FreeGiftPromo from "./free-gift-promo"

const ExtraSavingContainer = styled(Box).attrs((props) => ({
  bg: props.giftCardPromo ? "#F8F4D2" : "#DBF0CF",
  borderColor: props.giftCardPromo
    ? "#A47B1C"
    : { _: "#91D78A", tablet: "#00a900" },
  borderStyle: "solid",
  borderWidth: "1px",
  mt: { tablet: 4 },
  mx: "auto",
  p: { _: "10px", tablet: "14px 18px" },
  width: { desktop: "740px", tablet: "100%" },
  ...props.theme.cart.extraSavingContainer,
  ...props,
}))`
  &:empty {
    border: none;
    margin: 0;
    padding: 0;
  }
`

const HolidayMsg = styled(Typography)`
  color: ${(props) => props.theme.colors.greenDarker};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.03em;
  ${(props) => props.theme.mediaQueries.tablet`
    font-size: 18px;
  `}
`

// const StyledLink = styled(Link)`
//   color: ${(props) => props.theme.colors.primary};
//   font-size: 14px;
//   font-weight: 500;
//   letter-spacing: -0.03em;
//   line-height: 16px;
//   text-decoration: underline;
//   &:hover {
//     color: ${(props) => props.theme.colors.primary};
//     text-decoration: none;
//   }
// `

const InternalPromos = ({ availableHoliday }) => {
  // const bmsmEnabled = summary.bmsm_enabled
  // const doubleJewlsPromo = summary.promo_double_jewls
  // const doubleJewlsPromoMessage = summary.promo_double_jewls_messaging

  let content = null
  // let content = <BridalDiscount />
  // let doubleJewlsContent = null
  // let giftCardPromo = false

  // const promotions = sl.config.enable_name_necklace_promo

  if (availableHoliday) {
    return (
      <ExtraSavingContainer>
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon
            color="greenDarker"
            fontSize="20px"
            icon={faTruckFast}
            mr={{ _: 1, tablet: "6px" }}
          />
          {/* <Image
            alt="Holiday Delivery"
            height="15px"
            src={require("images/cart/promos/fast-shipping.svg")}
            width="26px"
          /> */}
          <Box>
            <MediaBox lessThan="tablet">
              <HolidayMsg>
                Order Now for{" "}
                {sl.config.guaranteed_holiday_alias ||
                  sl.config.guaranteed_holiday}{" "}
                Delivery!
              </HolidayMsg>
            </MediaBox>
            <MediaBox greaterThanOrEqual="tablet">
              <HolidayMsg>
                Order Now for{" "}
                {sl.config.guaranteed_holiday_alias ||
                  sl.config.guaranteed_holiday}{" "}
                Delivery!
              </HolidayMsg>
            </MediaBox>
          </Box>
        </Flex>
      </ExtraSavingContainer>
    )
  }
  // if (showHolidayCutoff) {
  //   content = <InstantGift />
  // } else if (sl.config.gift_card_promo.enable) {
  //   if (amountNeeded > 0) {
  //     giftCardPromo = true
  //   }

  //   content = <GiftCardPromo />
  // if (sl.config.multi_item_discount) {
  //   if (bmsmEnabled) content = <MultiItemDiscount refs={refs} />
  //   else {
  //     content = null
  //   }
  // }
  // if (doubleJewlsPromo) {
  //   doubleJewlsContent = (
  //     <>
  //       <FontAwesomeIcon fontSize="18px" icon={faGem} />
  //       <Typography fontSize="16px" fontWeight="700">
  //         {doubleJewlsPromoMessage}
  //       </Typography>
  //     </>
  //   )
  // }

  if (!content) return null

  return (
    <>
      {/* <ExtraSavingContainer
        doubleJewlsPromo={doubleJewlsPromo}
        giftCardPromo={giftCardPromo}
      >
        {content}
        {doubleJewlsPromo && (
          <MediaFlex
            alignItems="center"
            flexGrow="1"
            gap="6px"
            greaterThanOrEqual="tablet"
            justifyContent="center"
            mt="12px"
          >
            {doubleJewlsContent}
          </MediaFlex>
        )}
      </ExtraSavingContainer> */}
      {/* {doubleJewlsPromo && (
        <ExtraSavingContainer
          alignItems="center"
          as={MediaBox}
          gap="6px"
          lessThan="tablet"
        >
          {doubleJewlsContent}
        </ExtraSavingContainer>
      )}
      {promotions && summary.promotion_discount >= 0 && (
        <ExtraSavingContainer
          as={MediaBox}
          lessThan="tablet"
          textAlign="center"
        >
          <Flex
            alignItems="center"
            flexGrow="1"
            gap="6px"
            justifyContent="center"
            mb="4px"
          >
            <FontAwesomeIcon
              color={{ _: "greenDarker", tablet: "black" }}
              fontSize="18px"
              icon={faGifts}
            />
            <Typography
              color={{ _: "greenDarker", tablet: "black" }}
              fontSize="16px"
              fontWeight="700"
              lineHeight="18px"
              textAlign="left"
            >
              Buy any 2+ Name Necklaces
            </Typography>
          </Flex>
          <Flex alignItems="baseline" gap="6px" justifyContent="center">
            <Typography
              fontSize="14px"
              fontWeight="500"
              letterSpacing="-0.03em"
              lineHeight="16px"
            >
              Save an extra 15% on each
            </Typography>
            <StyledLink data-lc="promos" to="/all-name-initial-necklaces">
              Shop Name Necklaces
            </StyledLink>
          </Flex>
        </ExtraSavingContainer>
      )} */}
    </>
  )
}

const mapStateToProps = (state) => {
  // let amountNeeded =
  //   sl.config.gift_card_promo.minimum -
  //   parseFloat(state.cart.summary?.cart_total || 0)

  return {
    // amountNeeded,
    availableHoliday: state.cart.show_available_for_holiday,
    // giftCardPromo: state.giftCardPromo,
    summary: state.cart.summary,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalPromos.propTypes = {
  // amountNeeded: PropTypes.number,
  availableHoliday: PropTypes.bool,
  // refs: PropTypes.object,
  summary: PropTypes.object,
}

const Promos = connect(mapStateToProps, mapDispatchToProps)(InternalPromos)

export default Promos
