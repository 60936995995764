import { faGift } from "@jewlr/pro-light-svg-icons/faGift"
import { faMinus } from "@jewlr/pro-regular-svg-icons/faMinus"
import { faPlus } from "@jewlr/pro-regular-svg-icons/faPlus"
import { faChevronDown } from "@jewlr/pro-solid-svg-icons/faChevronDown"
import { faChevronUp } from "@jewlr/pro-solid-svg-icons/faChevronUp"
import { Expand } from "@jewlr/storybook/animate"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  MediaBox,
  MediaFlex,
  Typography,
  Image,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { removeGiftWrap, updateGiftProperties } from "areas/cart/store/actions"
import { getDATA } from "areas/landing/elements/landing-packaging"
import { jewlr_t } from "helpers/application"
import { getImgSrcSet } from "helpers/image"

import { ToggleIcon } from "../components"

import GiftWraps from "./gift-wraps"
import GreetingCard from "./greeting-card"

const Container = styled(Box).attrs((props) => ({
  ...props.theme.cart.giftOptions.container,
}))``

const GiftIcon = styled(FontAwesomeIcon).attrs((props) => ({
  icon: faGift,
  mr: 1,
  ...props,
}))``
const AddonToggle = styled(Typography).attrs((props) => ({
  color: "primary",
  mr: "auto",
  ...props.theme.cart.giftOptions.addonToggle,
  ...props,
}))``

const InternalGiftOptions = ({
  hasGiftPackageOptions,
  hasGreetingCardOptions,
  hasSelectedOption,
  item,
}) => {
  const [showGift, toggleShowGift] = useState(hasSelectedOption)
  const isBogarz = item.supplier === "Bogarz"

  const { image } = getDATA()
  const imgSrcSet = getImgSrcSet(image.mobile, 676, [110, 156, 220, 312])

  return (
    <Container>
      <Box as="h4" m="0" p="0">
        <Button
          aria-controls={`cart-line-item-gift-option-content-${item.id}`}
          aria-expanded={showGift}
          aria-label={`Add a ${jewlr_t(
            "terms.personalized"
          )} card and gift wrap`}
          display="block"
          id={`cart-line-item-gift-option-heading-${item.id}`}
          onClick={() => toggleShowGift(!showGift)}
          variant="text"
          width="100%"
        >
          <Flex
            alignItems="center"
            color="link"
            data-cy={`cart-line-item-packaging-checkbox-${item.style_code}`}
            justifyContent="space-between"
            width="100%"
          >
            <Flex alignItems="center">
              <GiftIcon />
              <AddonToggle>Is This A Gift?</AddonToggle>
            </Flex>
            <MediaBox greaterThanOrEqual="tablet">
              <Expand show={showGift}>
                <ToggleIcon icon={faChevronUp} width="9px" />
              </Expand>
            </MediaBox>
            <MediaFlex alignItems="center" lessThan="tablet">
              <ToggleIcon fontSize="14px" icon={showGift ? faMinus : faPlus} />
            </MediaFlex>
          </Flex>
          <Expand show={!showGift}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb={{ _: 2, tablet: 0 }}
              mt={{ _: 2, tablet: "10px" }}
            >
              <Flex alignItems="center">
                <Image
                  alt="Gift packaging"
                  ratio="676 / 400"
                  sizes="(max-width: 767px) 110px, 156px"
                  srcSet={imgSrcSet}
                  width={{ _: "110px", tablet: "156px" }}
                />
                <Box textAlign="left">
                  {isBogarz ? (
                    <Typography
                      fontSize={{ _: "14px", tablet: "16px" }}
                      fontWeight={500}
                      letterSpacing="-0.03em"
                      lineHeight="20px"
                    >
                      Add custom gift wrap for the ultimate gifting experience.
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        fontSize={{ _: "14px", tablet: "16px" }}
                        fontWeight={500}
                        letterSpacing="-0.03em"
                        lineHeight="20px"
                      >
                        Add a {jewlr_t("terms.personalized")} card and gift wrap
                      </Typography>
                      <Typography
                        fontSize={13}
                        fontWeight={300}
                        letterSpacing="-0.03em"
                        lineHeight="14px"
                      >
                        Choose your packaging add-ons for the ultimate gifting
                        experience.
                      </Typography>
                    </>
                  )}
                </Box>
              </Flex>
              <MediaFlex alignItems="center" greaterThanOrEqual="tablet">
                <Typography
                  color="primary"
                  fontSize={12}
                  fontWeight={500}
                  letterSpacing="-0.03em"
                  lineHeight="14px"
                  ml="auto"
                  mr="6px"
                >
                  {hasGreetingCardOptions
                    ? "Add Gift Packaging"
                    : "Add Gift Wrap"}
                </Typography>
                <ToggleIcon icon={faChevronDown} width="9px" />
              </MediaFlex>
            </Flex>
          </Expand>
        </Button>
      </Box>
      <Expand
        aria-labelledby={`cart-line-item-gift-option-content-${item.id}`}
        id={`cart-line-item-gift-option-heading-${item.id}`}
        pt={3}
        show={showGift}
      >
        {hasGreetingCardOptions && <GreetingCard item={item} />}
        {hasGiftPackageOptions && <GiftWraps item={item} />}
      </Expand>
    </Container>
  )
}

InternalGiftOptions.propTypes = {
  hasGiftPackageOptions: PropTypes.bool,
  hasGreetingCardOptions: PropTypes.bool,
  hasSelectedOption: PropTypes.bool,
  item: PropTypes.object,
  toggleGift: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  return {
    hasGiftPackageOptions: ownProps.item.gift_package_options.length > 0,
    hasGreetingCardOptions:
      (ownProps.item.greeting_card_options || []).length > 0,
    hasSelectedOption: ownProps.item.addons.some(({ addon_type }) =>
      ["GREETING_CARD", "PACKAGING"].includes(addon_type)
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const itemId = ownProps.item.id

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    toggleGift: (checked, giftProperties) => {
      if (checked) {
        if (Object.keys(giftProperties).length > 0) {
          dispatch(updateGiftProperties(itemId, giftProperties))
        }
      } else {
        dispatch(removeGiftWrap(itemId))
      }
    },
  }
}

const GiftOptions = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(InternalGiftOptions)

export default GiftOptions
